<template>
  <div class="login-container">
    <el-form
        ref="loginForm"
        :model="form"
        :rules="rules"
        class="login-form"
        auto-complete="on"
        label-position="left"
    >
      <div class="logo-container"></div>

      <div class="font-bold text-blue-600 text-center mt-8 mb-4">
        MODELLO DIGITALE PER LA CONFIGURAZIONE E PERSONALIZZAZIONE DELL’OSPEDALE DEL FUTURO<br>
        <br>
        <br>
        ENTRA E CREA IL TUO MODELLO!
      </div>
      <span class="my-2 block text-center text-sm">E' richiesto l'ultima versione aggiornata del sistema operativo ( Windows / OSX ) e chrome all'ultima versione</span>
      <el-form-item label="Email" prop="email">
        <el-input
            prefix-icon="el-icon-user"
            v-model="form.email"
            placeholder="Email"
            name="email"
            type="text"
            tabindex="1"
            auto-complete="on"
        />
      </el-form-item>

      <el-form-item label="Password" prop="password">
        <el-input
            prefix-icon="el-icon-lock"
            show-password
            ref="password"
            v-model="form.password"
            placeholder="Password"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="onLogin"
        />
      </el-form-item>

      <el-button
          :loading="isLoading"
          type="primary"
          style="width: 100%; margin-bottom: 30px"
          @click.native.prevent="onLogin"
      >Login
      </el-button
      >
      <div class="tips">
        <p> Non hai un account? <a class="font-bold text-blue-600 cursor-pointer" @click="goToRegister"> Registrati</a></p>
      </div>
    </el-form>
      <img alt="welcome" class="w-full col-span-2 object-contain h-full" src="../../../assets/welcome.jpg">
  </div>

</template>

<script>
import authService from '@/modules/auth/services/auth.services';

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [{
          required: true,
          message: 'Il campo email è obbligatorio',
          trigger: 'blur',
        }, {
          type: 'email',
          message: 'Inserisci un indirizzo email corretto',
          trigger: ['blur', 'change'],
        }],
        password: [{
          required: true,
          message: 'Il campo password è obbligatorio',
          trigger: 'blur',
        }, {
          min: 6,
          message: 'La lunghezza della password deve essere di almeno 5 caratteri',
          trigger: 'blur',
        }],
      },
      isLoading: false,
    };
  },
  methods: {
    /**
     * @desc On login
     */
    onLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this._login();
        } else {
          return false;
        }
      });
    },
    /**
     * @desc Login
     * @returns {Promise<void>}
     * @private
     */
    async _login() {
      this.isLoading = true;
      await authService.login(this.form).then((loginData) => {
        this.$store.dispatch('auth/loggedIn', {
          accessToken: loginData.data.data.access_token,
          user: loginData.data.data.user,
        });
        // Check if there's a pid
        if (this.$route.query && this.$route.query.pid) {
          this.$router.push(`/projects/${this.$route.query.pid}`);
        } else {
          this.$router.push({path: '/projects'});
        }
      }).catch((error) => {
        console.error(error);
        this.$notificationService.error('Errore durante l\'accesso. Riprovare.')
        this.$store.dispatch('auth/resetState');
      }).finally(() => this.isLoading = false)
    },
    goToRegister() {
      this.$router.push({path: '/register'});
    }
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  @apply grid grid-cols-3;
  min-height: 100vh;
  align-items: stretch;
  justify-items: center;
  width: 100%;
  overflow: hidden;

  .login-form {
    align-self: center;
    width: 100%;
    max-width: 100%;
    padding: 35px;
    margin: 0 auto;
    overflow: hidden;

    .logo-container {
      background-image: url("../../../assets/logoOspedaleFuturo.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 200px;
    }
  }

  .tips {
    font-size: 14px;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
}
</style>
